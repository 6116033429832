import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import CompanySearch from "../../components/CompanySearch"

const Companies = ({ data, location, msmembers }) => {
  const [allMembers, setAllMembers] = useState([])

  useEffect(() => {
    setAllMembers(data.company.nodes)
  }, [])

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Companies",
  })

  allMembers.sort(function (a, b) {
    if (a.companyProfiles.companyName.toLowerCase() < b.companyProfiles.companyName.toLowerCase()) return -1
    if (a.companyProfiles.companyName.toLowerCase() > b.companyProfiles.companyName.toLowerCase()) return -0
    return 0
  })

  const sortedMembers = []

  for (let item in allMembers) {
    if (allMembers[item].companyProfiles.primaryUser === "on" || allMembers[item].companyProfiles.primaryUser === "true" || allMembers[item].companyProfiles.primaryUser === true) {
      if (allMembers[item].companyProfiles.primaryUser) {
        sortedMembers.push(allMembers[item])
      }
    }
  }

  return (
    <Layout>
      <Seo title="Milwaukee Technology Companies" />
      <Breadcrumbs crumbs={crumbs} />
      <div className="container">
        <CompanySearch data={sortedMembers} name="company-search" type="company" title="Explore tech companies in Milwaukee" placeholder="Search by company name, positions, or perks" />
      </div>
    </Layout>
  )
}

export default Companies

export const searchQuery = graphql`
  query {
    company: allWpCompanyProfile {
      nodes {
        slug
        title
        id
        companyProfiles {
          backgroundColor
          backgroundImage
          backgroundImageAlt
          city
          companyDescription
          companyName
          companySize
          companyUrl
          contactEmail
          fieldGroupName
          industry
          logo
          logoAlt
          logoBackgroundColor
          membershipType
          memberstackId
          perksBenefits
          primaryUser
          phoneNumber
          state
          streetAddress
          zip
        }
      }
    }
  }
`
