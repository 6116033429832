import React, { useState, useMemo } from "react"
import FormInput from "./FormInput"

import CompanyResults from "./CompanyResults"
import {INDUSTRIES_LIST} from "../constants/industries";

const Search = ({ title, data, filters, placeholder, name, type, getFeaturedCompanyList, getMembershipByEnhance, getMembershipByBase, getMembershipByEntry, getEntryCityState }) => {
  const [searchField, setSearchField] = useState("")
  const [searchShow, setSearchShow] = useState(false)
  const [active, setActive] = useState(false)
  const [getFilter, setActiveFilter] = useState("")
  const [getPerks, setPerks] = useState("")
  const [getRemoteOption, setGetRemoteOption] = useState("")
  const [getFlexibleHours, setGetFlexibleHours] = useState("")

  const perksBenefits = [
    { label: "Flexible Schedule", value: "Flexible Schedule" },
    { label: "Remote Work Options", value: "Remote Work Options" },
    { label: "Diversity Employee Resource Groups", value: "Diversity Employee Resource Groups" },
    { label: "Health Benefits", value: "Health Benefits" },
    { label: "Dental & Vision Benefits", value: "Dental & Vision Benefits" },
    { label: "401k / HSA / Retirement", value: "401k / HSA / Retirement" },
    { label: "Paid Time Off", value: "Paid Time Off" },
    { label: "Wellness Programs", value: "Wellness Programs" },
    { label: "Mental Health Resources (EAP)", value: "Mental Health Resources (EAP)" },
    { label: "Company Outings", value: "Company Outings" },
    { label: "Food / Stock Kitchen", value: "Food / Stock Kitchen" },
    { label: "Games Recreation", value: "Games Recreation" },
    { label: "Business Casual Attire", value: "Business Casual Attire" },
    { label: "Training Budget", value: "Training Budget" },
    { label: "Stock Options", value: "Stock Options" },
    { label: "Parking + Transportation Options", value: "Parking + Transportation Options" },
    { label: "Relocation Packages", value: "Relocation Packages" },
  ]

  const industry = INDUSTRIES_LIST;

  const handleChange = (e) => {
    setSearchField(e)
    if (e === "") {
      setSearchShow(false)
    } else {
      setSearchShow(true)
    }
  }

  const showFilters = (e) => {
    setActive((active) => !active)
  }

  const filteredIndustry = useMemo(() => {
    const hasIndustryFilter = Object.values(getFilter).includes(true)
    const hasRemoteOption = Object.values(getRemoteOption).includes(true)
    const hasFlexibleHours = Object.values(getFlexibleHours).includes(true)

    const matchIndustries = (industry) => {
      if (hasIndustryFilter) {
        return industry.companyProfiles.industry?.split(",").some((category) => getFilter[category] === true)
      } else return true
    }

    const matchPerks = (perks) => {
      let perksArr = []
      perksArr = JSON.parse(perks.companyProfiles.perksBenefits)
      if (getPerks) {
        let perksWorkOption = false
        if (perksArr?.find((o) => o.label === getPerks)) {
          perksWorkOption = true
        }
        return perksWorkOption === true
      } else return true
    }

    const matchRemoteOpt = (remote) => {
      let remoteArr = []
      remoteArr = JSON.parse(remote.companyProfiles.perksBenefits)
      if (hasRemoteOption) {
        let remoteWorkOption = false
        if (remoteArr?.find((o) => o.label === "Remote Work Options")) {
          remoteWorkOption = true
        }
        return remoteWorkOption === true
      } else return true
    }

    const matchFlexibleHours = (flex) => {
      let flexArr = []
      flexArr = JSON.parse(flex.companyProfiles.perksBenefits)
      if (hasFlexibleHours) {
        let flexWorkOption = false
        if (flexArr?.find((o) => o.label === "Flexible Schedule")) {
          flexWorkOption = true
        }
        return flexWorkOption === true
      } else return true
    }

    return data
      .filter((company) => {
        return company.companyProfiles.companyName?.toLowerCase().includes(searchField.toLowerCase()) || company.companyProfiles.companyDescription?.toLowerCase().includes(searchField.toLowerCase()) || company.companyProfiles.industry?.toLowerCase().includes(searchField.toLowerCase()) || company.companyProfiles.location?.toLowerCase().includes(searchField.toLowerCase()) || company.companyProfiles.perksBenefits?.toLowerCase().includes(searchField.toLowerCase())
      })
      .filter(matchIndustries)
      .filter(matchRemoteOpt)
      .filter(matchFlexibleHours)
      .filter(matchPerks)
  }, [data, getFilter, getPerks, searchField, getRemoteOption, getFlexibleHours])

  const handleChecked = (index, text) => (event) => {
    setActiveFilter((prev) => ({
      ...prev,
      [index]: !prev[index],
      [text]: event.target.checked,
    }))
  }

  const handleRemoteOptions = (text) => (event) => {
    setGetRemoteOption((prev) => ({
      ...prev,
      [text]: event.target.checked,
    }))
  }

  const handleFlexibleHours = (text) => (event) => {
    setGetFlexibleHours((prev) => ({
      ...prev,
      [text]: event.target.checked,
    }))
  }

  const industryFilters = (
    <div className="search-filters--types industry">
      <h4>Industry</h4>
      <div className="tags--container">
        {industry.map((item, index) => {
          return (
            <label htmlFor={`industry-${index}`} key={index} className={`${item.categoryClass} ${getFilter[index] ? "checked" : ""}`} onChange={handleChecked(index, item.label)}>
              <input type="checkbox" name={item.label} id={`industry-${index}`} defaultValue={item.label} />
              {item.label}
            </label>
          )
        })}
      </div>
    </div>
  )

  const clearFilters = (e) => {
    e.preventDefault()
    window.location.reload()
  }

  const applyFilterButton = (
    <div className="search-filters--controls">
      <button className="button button--primary" onClick={showFilters}>
        Apply Filters
      </button>
      <a href="#" className="link" onClick={clearFilters}>
        Clear Filters
      </a>
    </div>
  )
  if (type === "company") {
    filters = (
      <div className={`search-filters ${active ? "active" : ""}`}>
        <div className="search-filters--container">
          {industryFilters}
          <div className="search-filters--options">
            <div className="search-filters--types perks-benefits">
              <h4>Perks</h4>
              <FormInput name="perks" type="dropdown" children={perksBenefits} value={getPerks} onChange={setPerks} />
            </div>
            <div className="search-filters--types remote">
              <h4>Remote</h4>
              <div className="search--checkbox">
                <FormInput name="Remote Options" type="checkbox" label="Remote Options" onChange={handleRemoteOptions("Remote Work Options")} />
                <FormInput name="Flexible Schedule" type="checkbox" label="Flexible Schedule" onChange={handleFlexibleHours("Flexible Schedule")} />
              </div>
            </div>
          </div>
        </div>
        {applyFilterButton}
      </div>
    )
  }

  let c = []
  for (let item in filteredIndustry) {
    c.push(filteredIndustry[item])
  }

  let order = ["Premium", "Enhanced", "Base", "Bronze (Base)", "Entry", "Community (Entry)"]

  let filteredCompanies = c.sort((a, b) => {
    return order.indexOf(a.membershipType) - order.indexOf(b.membershipType)
  })

  return (
    <>
      <div className="search-resource">
        <h1>{title}</h1>
        <div className="formItems">
          <FormInput name={name} type="search" placeholder={placeholder} onChange={handleChange} />
          <div className="search--controls">
            <i className="fas fa-search"></i>
            <span className="advanced-search" onClick={showFilters}>
              <em role="button" aria-label="Advanced Search" tabIndex="0">
                Advanced Search
              </em>{" "}
              <i className="fas fa-filter"></i>
            </span>
          </div>
        </div>
      </div>
      {filters}
      <h3>Company Listings</h3>
      <CompanyResults posts={filteredCompanies} />
    </>
  )
}

export default Search
